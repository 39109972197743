import React from "react"

import { CategoriesFilterPanel } from "../../components/blog/categories-filter-panel"
import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/zdrave-jidlo-praha.jpg"
import styles from "./post-grid.module.css"

const Post7 = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Zdravé krabičky v Praze: rozvoz zdarma | NutritionPro"}
        description={
          "Kam na zdravé jídlo v Praze? Nechte si ho přivést domů. Rozvážíme zdravé krabičky po Praze zdarma. Vyberte si z unikátních programů vyvážené stravy."
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-jidlo-praha">
            Zdravé krabičky v Praze: rozvoz zdarma
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Zdravé krabičky v Praze: rozvoz zdarma"
              date="26.03.2022"
            />
            <div>
              <p className={styles.postText}>
                Víte, že většina snah o vyváženou stravu nebo hubnutí skončí
                většinou tak, že na to lidé nemají dostatek času? Není to tak
                snadné, jak by se mohlo zdát. Během dne na nás čeká mnoho
                nástrah a pokušení – a pokud nejste zrovna najedení, snadno jim
                podlehnete. Právě to je důvod,{" "}
                <strong>proč nejsou běžné diety efektivní</strong> – u většiny z
                nich máte hlad téměř stále. Existuje ale jiná možnost.{" "}
                <strong>
                  Hubnout bez hladovění a dopřát si vyvážené a chutné jídlo.
                </strong>
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>
                Znáte NutritionPro? Zdravé krabičky v Praze
              </h5>
              <p className={styles.postText}>
                Právě díky nám z NutritionPro{" "}
                <strong>není zdravé stravování v Praze žádná věda</strong>. Jsme
                nový, rychle rostoucí startup z oblasti FoodTech. Umíme{" "}
                <strong>vytvořit jídelníček na míru</strong>, podle vašich
                potřeb, například jídelníček na hubnutí nebo udržení váhy. Každý
                den pro vás připravíme <strong>chutné a vyvážené jídlo</strong>,
                které vám <strong>doručíme až domů</strong>. Ano, konečně rozvoz
                zdravého jídla po Praze. Tak jednoduché to s námi může být.
              </p>
              <h5 className={styles.postTitle}>4 unikátní programy</h5>
              <p className={styles.postText}>
                Nabízíme hned <strong>4 jedinečné programy</strong>, se kterými
                se dostanete tam, kam potřebujete. Bez starostí, bez nákupu
                jídel, bez vaření, bez počítání kalorií – zkrátka{" "}
                <strong>jednoduše, navíc chutně a zdravě</strong>.
              </p>
              <p className={styles.postText}>
                <a href="https://nutritionpro.cz/products/product-1/">
                  Hubnoucí program
                </a>{" "}
                je navržen jako 3 nebo 5chodové menu na 5 až 6 dní v týdnu.
                Navíc si můžete nastavit{" "}
                <strong>rychlost, se kterou budete chtít zhubnout</strong>.
                Sestavíme vám jídelníček na míru vašim cílům z prémiových
                surovin.
              </p>
              <p className={styles.postText}>
                <a href="https://nutritionpro.cz/products/product-2/">
                  Nabírací program
                </a>{" "}
                je sestaven tak, abyste{" "}
                <strong>nabrali především svalovou hmotu</strong>. Vhodný je
                zejména pro ty z vás, kteří pravidelně cvičí a chtějí{" "}
                <strong>nabrat váhu</strong>. Opět si můžete zvolit 3 nebo
                5chodové menu vyvážené stravy.
              </p>
              <p className={styles.postText}>
                <a href="https://nutritionpro.cz/products/product-3/">
                  Udržovací program
                </a>{" "}
                je určen pro všechny, kteří nechtějí přibrat, ale ani hubnout.
                Jsou spokojeni se svou váhou. Jedná se o{" "}
                <strong>dlouhodobě udržitelné zdravé stravování</strong>.
              </p>
              <p className={styles.postText}>
                <a href="https://nutritionpro.cz/products/product-4/">
                  Office Pack
                </a>{" "}
                představuje{" "}
                <strong>zdravé, lehčí a nutričně vyvážené jídlo</strong>.
                Ušetříte díky němu mnoho času, protože nemusíte vařit. Každý den
                můžete mít chutný oběd a večeři.
              </p>
              <h5 className={styles.postTitle}>
                Vylaďte si jídelníček do detailu
              </h5>
              <p className={styles.postText}>
                Svůj <strong>jídelníček si můžete vyladit do detailu</strong>.
                Na výběr máte délku programu od 2 týdnů do 3 měsíců. U některých
                programů si také můžete zvolit počet jídel nebo počet kalorií.
                Pokud si nevíte rady, rádi vám poradíme s výběrem na základě
                našeho chytrého algoritmu a týmu odborníků. Pojďte do toho s
                námi.
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default Post7
